import React, { useContext } from "react";
import { UserContext } from "@/context/UserContext";
import { BroadcastContext } from "@/context/BroadcastContext";
import { Tooltip } from "@/components/Tooltip";
import { StreamMessagesType } from "@/types/chatTypes";
import { ReactComponent as DeleteIcon } from "./assets/delete-icon.svg";
import { ReactComponent as LikeIcon } from "./assets/like-icon.svg";
import classNames from "classnames";
import { useToggle } from "react-use";
import useMedia from "@/hooks/useMedia";
import Popup from "@/components/Popup";
import WarningMessage from "@/components/WarningMessage";
import Avatar from "@/components/Avatar";
import ModerationStatus from "./ModerationStatus";
import MessageInfoIcons from "./MessageInfoIcons";
import MessageHeader from "./MessageHeader";
import ModerationControls from "./ModerationControls";
import * as Popover from "@radix-ui/react-popover";
import "./index.scss";

type Props = {
    message: StreamMessagesType,
    isForModerators: boolean,
    isCommonChatModerated?: boolean,
    forStage: boolean,
}

const MessageCard: React.FC<Props> = ({
    message,
    isForModerators,
    isCommonChatModerated,
    forStage = false,
}) => {
    const { userInfo } = useContext(UserContext);
    const { deleteMessage, addLikeToMessage, removeLikeFromMessage, pinnedMessage } = useContext(BroadcastContext);

    const isCurrentUser = message?.user?.id === userInfo?.id;
    const chatType = message?.type;
    const isPinned = message?.id === pinnedMessage?.id;
    const fullName: string = `${message?.user?.surname} ${message?.user?.name}`;

    const [isDeleteActive, toggleDelete] = useToggle(false);
    const { isDesktop, isMobile } = useMedia();

    const handleDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        deleteMessage(message?.id);

        if (isDeleteActive) {
            toggleDelete(false);
        }
    }

    const handleLike = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        if (!userInfo) return;

        if (message?.has_rate_for_message) {
            removeLikeFromMessage(message?.id, chatType);

        } else {
            addLikeToMessage(message?.id, chatType);
        }
    }

    const handlClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        toggleDelete()
    }

    return (
        <>
            <Popover.Root open={isDeleteActive && isDesktop} modal>
                <div
                    id={message?.id.toString()}
                    className={classNames({
                        "MessageCard": true,
                        "MessageCard--current-user": isCurrentUser && !isForModerators && !forStage,
                        "MessageCard--active": isDeleteActive && isDesktop,
                    })}
                >
                    <div className="MessageCard__Main">
                        <Avatar
                            className="MessageCard__Avatar"
                            name={message?.user?.name}
                            src={message?.user.avatar_url}
                        />
                        <Popover.Anchor asChild>
                            <div className="MessageCard__Body">
                                <MessageHeader fullName={fullName} created_at={message?.created_at} />
                                <p className="MessageCard__Text">{message?.message}</p>

                                { !isForModerators && message.moderation_status === "approved" && (
                                    <MessageInfoIcons
                                        forStage={forStage}
                                        isPinned={isPinned}
                                        isCurrentUser={isCurrentUser}
                                        likes={message?.rating}
                                        user_has_rate_for_message={message?.has_rate_for_message}
                                        handleLike={handleLike}
                                    />
                                )}

                                {isForModerators && (
                                    <ModerationControls
                                        likes={message?.rating}
                                        userId={message?.user.id}
                                        messageId={message?.id}
                                        isPinned={message?.is_pinned}
                                        moderationStatus={message?.moderation_status}
                                        isCommonChatModerated={isCommonChatModerated}
                                    />
                                )}
                            </div>
                        </Popover.Anchor>

                        {!isForModerators && !forStage && (
                            <div className="MessageCard__Controls">
                                {
                                    isCurrentUser && !isDeleteActive &&
                                    (<Tooltip
                                        className="MessageCard__Tooltip"
                                        tooltip="Удалить сообщение"
                                        disabled={!isDesktop}
                                        placement="top"
                                        shift
                                        flip
                                    >
                                        <Popover.Trigger asChild>
                                            <button
                                                className="MessageCard__ControlsBtn MessageCard__ControlsBtn--gray"
                                                type="button"
                                                onClick={handlClick}
                                            >
                                                <DeleteIcon />
                                            </button>
                                        </Popover.Trigger>
                                    </Tooltip>)
                                }

                                {!isCurrentUser && !message?.has_rate_for_message &&
                                    (<Tooltip
                                        className="MessageCard__Tooltip"
                                        tooltip="Поставить лайк"
                                        disabled={!isDesktop}
                                        placement="top"
                                        shift
                                        flip
                                    >
                                        <button
                                            className="MessageCard__ControlsBtn"
                                            type="button"
                                            onClick={handleLike}
                                        >
                                            <LikeIcon />
                                        </button>
                                    </Tooltip>)
                                }
                            </div>
                        )}
                    </div>

                    <Popover.Portal>
                        <Popover.Content sideOffset={4} hideWhenDetached onInteractOutside={toggleDelete}>
                            <div className="MessageCard__Delete">
                                <button
                                    type="button"
                                    className="MessageCard__Delete-btn"
                                    onClick={handlClick}
                                    >
                                    Отмена

                                </button>
                                <button
                                    type="button"
                                    className="MessageCard__Delete-btn MessageCard__Delete-btn--delete"
                                    onClick={handleDelete}
                                >
                                    Удалить
                                </button>
                            </div>
                        </Popover.Content>
                    </Popover.Portal>
                </div>
            </Popover.Root>

            {isCurrentUser && message?.moderation_status !== "approved" && !isForModerators &&
                <div className="MessageCard__Moderation">
                    <ModerationStatus moderation_status={message?.moderation_status} chatType={chatType} />
                </div>
            }

            {isDeleteActive && isDesktop && (<div className="MessageCard__Overlay"></div>)}

            {!isDesktop &&
                <Popup
                    className="MessageCard__Popup"
                    isPopupActive={isDeleteActive}
                    closePopup={toggleDelete}
                    position={isMobile ? "bottom" : "center"}
                    withCloseBtn
                >
                    <WarningMessage
                        togglePopup={() => toggleDelete(false)}
                        executeFunc={() => deleteMessage(message?.id)}
                        text="Это действие нельзя будет отменить."
                        question="Удалить сообщение?"
                    />
                </Popup>
            }
        </>
    )
}

export default MessageCard;