
import React from "react";
import BlockedShadow from "./assets/blocked-shadow.png";
import Typography from "@/components/Typography";
import { ReactComponent as BlockedIcon } from './assets/blocked-icon.svg';
import "./index.scss";

const UserBlocked: React.FC = () => {
    return (
        <div className="UserBlocked">
            <div className="UserBlocked__IconContainer">
                <BlockedIcon className="UserBlocked__Icon" />
                <img className="UserBlocked__IconShadow" src={BlockedShadow} alt="blocked"/>
            </div>
            <div className="UserBlocked__Body">
                <Typography className="UserBlocked__Title" component="div" size="lg" weight="semiBold" color="dark" isCentered>
                    Ваш аккаунт был заблокирован
                </Typography>
                <Typography className="UserBlocked__Subtitle" component="p" size="xs" weight="regular" color="gray" isCentered>
                    В связи с нарушением правил ваш аккаунт был заблокирован. Вы больше не можете писать сообщения и задавать вопросы, ваши предыдущие сообщения были удалены.
                </Typography>
            </div>
        </div>
    )
}

export default UserBlocked;