import React, { useContext, useEffect } from "react";
import { BroadcastContext } from "@/context/BroadcastContext";
import LiveChat from "@/components/LiveChat";
import Container from "@/components/Container";
import BroadcastHeader from "@/components/BroadcastHeader";
import { ErrorBoundary } from "react-error-boundary";
import LiveChatFallback from "@/components/LiveChatFallback";
import "./index.scss";


const ModeratedQuestions: React.FC = () => {
    const { forSpeakerBroadcastMessages, toggleChatType } = useContext(BroadcastContext);

    useEffect(() => {
        toggleChatType("for_speaker");
    }, [])

    return (
        <main className="ModeratedQuestions">
            <Container className="ModeratedQuestions__Container">
                <div className="ModeratedQuestions__Header">
                    <BroadcastHeader isForModerators />
                </div>
                <ErrorBoundary FallbackComponent={LiveChatFallback}>
                    <LiveChat
                        isCollapsible={false}
                        isForModerators={false}
                        chatData={forSpeakerBroadcastMessages}
                        chatType={"for_speaker"}
                        forStage
                    />
                </ErrorBoundary>
            </Container>
        </main>
    )
}

export default ModeratedQuestions;