import React, { useContext, useState } from 'react';
import { useFormik } from 'formik';
import { ReactComponent as SendOutlinedIcon } from "../assets/send-outlined-icon.svg";
import { ReactComponent as SendSolidGreenIcon } from "../assets/send-solid-green.svg";
import { ReactComponent as SendSolidGrayIcon } from "../assets/send-solid-gray.svg";
import { toastNotifications } from '@/components/Toastify';
import TextareaAutosize from "react-textarea-autosize";
import { chatSchema } from "@/Schema/chatInput";
import InputWarnings from '../components/InputWarnings';
import { ChatType, NewMessageType, UserStatus } from '@/types/chatTypes';
import classNames from 'classnames';
import { BroadcastContext } from '@/context/BroadcastContext';
import { UserContext } from '@/context/UserContext';
import "./index.scss";

type Props = {
    chatType: ChatType,
}

const ChatInput: React.FC<Props> = ({
    chatType,
}) => {
    const { activeBroadcast, sendMessage, isFetchingMessages, isUserBlocked } = useContext(BroadcastContext);
    const { userInfo } = useContext(UserContext);

    const userStatus: UserStatus = userInfo === undefined ? "notAuth" : "auth";

    const { values, handleChange, handleSubmit, handleBlur, errors, touched } = useFormik({
        initialValues: {
            chatInput: '',
        },
        validationSchema: chatSchema,
        onSubmit: async (values, actions) => {
            try {
                if (activeBroadcast && userInfo) {
                    const message: NewMessageType = {
                        stream_id: activeBroadcast?.id,
                        message: values.chatInput,
                        type: chatType,
                    }

                    sendMessage(message);
                    actions.resetForm();
                }
            } catch {
                toastNotifications.error("Что-то пошло не так. Повторите попытку позже");
            }
        }
    });

    const [isInputFocused, setIsInputFocused] = useState<boolean>(false);
    const isInputActive = values.chatInput.length > 0;

    const handleFocused = () => {
        setIsInputFocused(true)
    }

    const handleInput = (e: React.FocusEvent<HTMLTextAreaElement>) => {
        setIsInputFocused(false);
        handleBlur(e)
    }

    const isInputError = errors.chatInput && touched.chatInput && values.chatInput.length > 1000;

    return (
        <div className={classNames({
            "ChatInput__Container": true,
            "ChatInput__Container--focused": isInputFocused,
            "ChatInput__Container--error": isInputError,
            "ChatInput__Container--disabled": isFetchingMessages,
        })}>
            {
                userStatus === "auth" && !isUserBlocked ?
                    <>
                        <form
                            className="ChatInput__Form"
                            onSubmit={handleSubmit}
                        >
                            <TextareaAutosize
                                className="ChatInput"
                                id="chatInput"
                                name="chatInput"
                                placeholder="Введите сообщение..."
                                value={values.chatInput}
                                onChange={handleChange}
                                onBlur={handleInput}
                                onFocus={handleFocused}
                                rows={1}
                                maxRows={4}
                                disabled={isFetchingMessages}
                            />

                            <button
                                className="ChatInput__Btn"
                                type="submit"
                            >
                                {
                                    isInputActive ?
                                        <SendSolidGreenIcon />
                                        :
                                        isInputFocused?
                                            <SendOutlinedIcon />
                                            :
                                            <SendSolidGrayIcon />
                                    }
                            </button>
                        </form>
                        {isInputError && <p className='ChatInput__Error'>{errors.chatInput}</p>}
                    </>
                    :
                    <InputWarnings userStatus={isUserBlocked ? "blocked" : "notAuth"} />
            }
        </div>
    )
}

export default ChatInput;