import React from "react";
import Typography from "@/components/Typography";
import "./index.scss";

type Props = {
    title: string,
    description: string
}

const StreamTooltip: React.FC<Props> = ({
    title,
    description
}) => {
    return (
        <div className="StreamTooltip">
            <Typography component="div" size="sm" weight="semiBold" color="dark">
                {title}
            </Typography>
            <Typography component="div" size="sm" weight="regular" color="dark">
                <span
                    dangerouslySetInnerHTML={{
                        __html: description,
                    }}
                />
            </Typography>
        </div>
    )
}

export default StreamTooltip;