import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import FaqAccordion from "../FaqAccordion";
import Button from "components/Button";
import { FaqTabList } from "../types";
import useMedia from "@/hooks/useMedia";
import "./index.scss";

const FaqTabs: React.FC<FaqTabList> = ({ data }) => {
    const { isMobile } = useMedia();
    const [tabIndex, setTabIndex] = useState<number>(1);

    const { hash } = useLocation();

    useEffect(() => {
        if (hash === "#faq") {
            setTabIndex(1);
        }
    }, [hash]);

    const renderQuestions = data?.map((el, index) => {
        return (
            <TabPanel key={index}>
                <FaqAccordion questions_and_answers={el.questions_and_answers} />
            </TabPanel>
        );
    });

    const renderTabs = data.map((el, index) => {
        return (
            <Tab
            key={index}
            className="FaqTabs__Item"
            selectedClassName="FaqTabs__Item--Selected"
        >
            <Button
                className="FaqTabs__Button"
                tag="button"
                BtnType="button"
                text={el.title}
            />
        </Tab>
        );
    });

    return (
        <Tabs
            selectedIndex={tabIndex}
            onSelect={(index: number) => setTabIndex(index)}
            className="FaqTabs"
        >
            <TabList className="FaqTabs__List">
                {isMobile ? (
                    <div className="FaqTabs__ListMobileWrapper">
                        <div className="FaqTabs__ListMobile">
                                {renderTabs}
                        </div>
                    </div>
                ) : (
                    renderTabs
                )}
            </TabList>
            <div className="FaqTabs__Content">
                <div className="FaqTabs__ContentBlock">{renderQuestions}</div>
            </div>
        </Tabs>
    );
};

export default FaqTabs;
