import React from "react";
import { ChatType, ModerationStatusType } from "@/types/chatTypes";
import { ReactComponent as InfoIcon } from "../assets/info_circle.svg";
import { ReactComponent as RejectedIcon } from "../assets/closemark_circle.svg";
import "./index.scss";

type Props = {
    moderation_status: ModerationStatusType,
    chatType: ChatType,
}

const ModerationStatus: React.FC<Props> = ({ moderation_status, chatType }) => {

    return (
        <>
            {moderation_status === "on_moderation" &&
                <div className="ModerationStatus">
                    <InfoIcon className="ModerationStatus__Icon"/>
                    {chatType === "common" ? "Ваше сообщение на модерации!" : "Ваш вопрос на модерации!"}
                    <br />Как только он будет одобрен, сообщение отправится
                    <br />автоматически.
                </div>
            }

            {moderation_status === "rejected" &&
                <div className="ModerationStatus ModerationStatus--rejected">
                    <RejectedIcon className="ModerationStatus__Icon"/>
                    К сожалению, ваш вопрос
                    <br />не прошёл модерацию
                </div>
            }
        </>
    )
}

export default ModerationStatus;