import React, { useContext } from "react";
import ProgramContainer from "@/components/ProgramSchedule/common/ProgramContainer";
import { ScheduleContext } from "@/context/ScheduleContext";
import Loader from "@/components/Loader";
import Headline from "@/components/Headline";
import SessionContainer from "@/components/SessionCard/SessionContainer";
import ProgramIcons from "@/components/ProgramSchedule/ProgramIcons";
import "./index.scss";

type Props = {
    streamNum: number,
}

const StreamProgram: React.FC<Props> = ({ streamNum }) => {
    const { onlineProgram } = useContext(ScheduleContext);

    if (onlineProgram.length === 0 || !onlineProgram) {
        return <Loader />
    }

    return (
        <div className="StreamProgram">
            <Headline
                className="StreamProgram__Title"
                level="h2"
                theme="light"
            >
                Расписание стрима
            </Headline>

            {/* opening  */}
            {streamNum === 1 && (
                <SessionContainer
                    title={onlineProgram[0].TimeString}
                    icon={onlineProgram[0].streamingStatus === 'active' ? <ProgramIcons type="liveStream"/> : <ProgramIcons type="start"/>}
                    streamingStatus={onlineProgram[0].streamingStatus}>
                    <ProgramContainer
                        data={onlineProgram[0]}
                    />
                </SessionContainer>
            )}

            {/* discussion  */}
            {streamNum === 1 && (
                <SessionContainer
                    title={onlineProgram[1].TimeString}
                    icon={onlineProgram[1].streamingStatus === 'active' && <ProgramIcons type="liveStream"/>}
                    streamingStatus={onlineProgram[1].streamingStatus}>
                        <ProgramContainer
                            data={onlineProgram[1]}
                            mainStage
                            withLink
                            />
                </SessionContainer>
            )}

            {/* coffee_break  */}
            <SessionContainer
                title={onlineProgram[2].TimeString}
                icon={onlineProgram[2].streamingStatus === 'active' ? <ProgramIcons type="liveStream"/> : <ProgramIcons type="break"/>}
                streamingStatus={onlineProgram[2].streamingStatus}>
                    <ProgramContainer
                        data={onlineProgram[2]}
                    />
            </SessionContainer>

            {/* multiple_sessions 01 3/4/5 programs  */}
            <SessionContainer
                title={onlineProgram[2 + streamNum].TimeString}
                icon={onlineProgram[2 + streamNum].streamingStatus === 'active' && <ProgramIcons type="liveStream"/>}
                streamingStatus={onlineProgram[2 + streamNum].streamingStatus}>
                <ProgramContainer
                    data={onlineProgram[2 + streamNum]}
                />
            </SessionContainer>

            {/* coffee_break  */}
            <SessionContainer
                title={onlineProgram[6].TimeString}
                icon={onlineProgram[6].streamingStatus === 'active' ? <ProgramIcons type="liveStream"/> : <ProgramIcons type="break"/>}
                streamingStatus={onlineProgram[6].streamingStatus}>
                <ProgramContainer
                    data={onlineProgram[6]}
                />

            </SessionContainer>

            {/* multiple_sessions 02 7/8/9 programs   */}
            <SessionContainer
                title={onlineProgram[6 + streamNum].TimeString}
                icon={onlineProgram[6 + streamNum].streamingStatus === 'active' && <ProgramIcons type="liveStream"/>}
                streamingStatus={onlineProgram[6 + streamNum].streamingStatus}>
                <ProgramContainer
                    data={onlineProgram[6 + streamNum]}
                />
            </SessionContainer>

            {/* coffee_break  */}
            <SessionContainer
                title={onlineProgram[10].TimeString}
                icon={onlineProgram[10].streamingStatus === 'active' ? <ProgramIcons type="liveStream"/> : <ProgramIcons type="break"/>}
                streamingStatus={onlineProgram[10].streamingStatus}>
                <ProgramContainer
                    data={onlineProgram[10]}
                />
            </SessionContainer>

            {/* multiple_sessions 03 11/12/13 programs */}
            <SessionContainer
                title={onlineProgram[10 + streamNum].TimeString}
                icon={onlineProgram[10 + streamNum].streamingStatus === 'active' && <ProgramIcons type="liveStream"/>}
                streamingStatus={onlineProgram[10 + streamNum].streamingStatus}>
                <ProgramContainer
                    data={onlineProgram[10 + streamNum]}
                />
            </SessionContainer>

            {/* finish  */}
            <SessionContainer
                title={onlineProgram[14].TimeString}
                icon={onlineProgram[14].streamingStatus === 'active' ? <ProgramIcons type="liveStream"/> : <ProgramIcons type="finish"/>}
                streamingStatus={onlineProgram[14].streamingStatus}>
                <ProgramContainer
                    data={onlineProgram[14]}
                />
            </SessionContainer>
        </div>
    )
}

export default StreamProgram;