import React from "react";
import formatDate from "@/utils/formateDate";
import "./index.scss";

type Props = {
    fullName: string,
    created_at: string,
}

const MessageHeader: React.FC<Props> = ({
    fullName,
    created_at
}) => {
    const time = formatDate(created_at);

    return (
        <div className="MessageHeader">
                <span className="MessageHeader__Name">{fullName}</span>
            <span className="MessageHeader__Time">{time}</span>
        </div>
    )
}

export default MessageHeader;