import React from "react";
import classNames from "classnames";
import { ReactComponent as PinIcon } from "../assets/pin-icon.svg";
import HeartIcon from "./HeartIcon";
import "./index.scss";

type Props = {
    isPinned: boolean,
    user_has_rate_for_message: boolean,
    forStage: boolean,
    isCurrentUser: boolean,
    handleLike: (e: React.MouseEvent<HTMLButtonElement>) => void,
    likes: number
}

const MessageInfoIcons: React.FC<Props> = ({
    isPinned,
    user_has_rate_for_message,
    forStage = false,
    isCurrentUser = false,
    handleLike,
    likes
}) => {

    const haslikes = likes > 0;
    return (
        <div className="MessageInfoIcons">
            {haslikes && (
                <button
                    className={classNames("MessageInfoIcons__Likes", {
                        "MessageInfoIcons__Likes--isLiked": user_has_rate_for_message,
                        "MessageInfoIcons__Likes--disabled": forStage || isCurrentUser,
                    })}
                    onClick={handleLike}
                    type="button"
                    disabled={forStage || isCurrentUser}
                >
                    {likes}
                    <HeartIcon haslikes={haslikes} />
                </button>
            )}

            {isPinned && <PinIcon />}
        </div>
    )
}

export default MessageInfoIcons;