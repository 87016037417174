import React, { useContext, useEffect, useState } from "react";
import { BroadcastContext } from "@/context/BroadcastContext";
import { ScheduleContext } from "@/context/ScheduleContext";
import { useLocation, useSearchParams } from 'react-router-dom';
import Button from "@/components/Button";
import { Tooltip } from "@/components/Tooltip";
import classNames from "classnames";
import StreamTooltip from "./StreamTooltip";
import routes from "@/routes";
import useMedia from "@/hooks/useMedia";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import "./index.scss";

type Props = {
    isForModerators: boolean,
}

const commonStreamTypes = ["coffee_break_01", "coffee_break_02", "coffee_break_03"];

const BroadcastHeader: React.FC<Props> = ({ isForModerators }) => {
    const { isMobile, isDesktop } = useMedia();

    const { streamNum, activeBroadcast, toggleBroadcast, availableBroadcasts } = useContext(BroadcastContext);
    const { currentActiveStream } = useContext(ScheduleContext);
    const [searchParams, setSearchParams] = useSearchParams();

    const { pathname } = useLocation();
    const [isInBroadcastPage, setIsInBroadcastPage] = useState<boolean>(false);

    const handleClick = (newStreamNum: number) => {
        if (streamNum === newStreamNum) return;
        // open new tab when click !
        if (!isInBroadcastPage && !isForModerators) {
            const baseUrl = process.env.PUBLIC_URL + '/broadcast';
            const searchUrl = new URLSearchParams(window.location.search);
            searchUrl.set('stream', `${newStreamNum}`);
            const newTabURL = baseUrl + '?' + searchUrl.toString();
            window.open(newTabURL, '_blank');
        } else {
            searchParams.set('stream', `${newStreamNum}`);
            setSearchParams(searchParams)

            toggleBroadcast(newStreamNum);
        }
    }

    useEffect(() => {
        const streamNum = searchParams.get('stream');

        if (streamNum && availableBroadcasts.length > 0) {
            toggleBroadcast(+streamNum)
        }

    }, [searchParams, availableBroadcasts]);

    useEffect(() => {

        if (pathname.includes(`${routes.broadcast}`)) {
            setIsInBroadcastPage(true);
        } else {
            setIsInBroadcastPage(false)
        }

    }, [pathname])

    const renderBtn = availableBroadcasts?.map((el, index) => {
        const streamNum = index + 1;
        const streamId = el.id;
        const streamType = currentActiveStream[index]?.streamType ?? currentActiveStream[0]?.streamType;
        const streamTitle = commonStreamTypes.includes(streamType) ? currentActiveStream[0]?.title : currentActiveStream[index]?.title;

        return (
            <SwiperSlide
                key={index}
            >
                <Tooltip
                    tooltip={(
                        <StreamTooltip
                            title="Подробнее о стриме:"
                            description={streamTitle}
                        />
                    )}
                    disabled={!isDesktop || !streamTitle}
                    placement="top"
                    offset={20}
                    shift
                >
                    <Button className={classNames({
                        "BroadcastHeader__Btn": true,
                        "BroadcastHeader__Btn--disabled": false,
                        "BroadcastHeader__Btn--active": activeBroadcast?.id === streamId,
                    })}
                        tag="button"
                        BtnType="button"
                        size='lg'
                        type='transparent'
                        disabled={false}
                        isWide
                        onClick={() => handleClick(streamNum)}
                    >
                        <div className="BroadcastHeader__Title-container">
                            <div className="BroadcastHeader__Subtitle">Стрим&nbsp;{streamNum}</div>
                            <p className="BroadcastHeader__Title">
                                {streamTitle ? (
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: streamTitle,
                                        }}
                                    />
                                    ) : (
                                    '—'
                                )}
                                </p>
                        </div>
                    </Button>
                </Tooltip>
            </SwiperSlide>
        )
    });


    return (
        <div className="BroadcastHeader">
            {isMobile ? (
                <Swiper
                    className="BroadcastHeader__Swiper"
                    slidesPerView={1.8}
                    spaceBetween={8}
                    slidesOffsetBefore={16}
                    slidesOffsetAfter={16}
                    centeredSlides
                    centeredSlidesBounds
                    slideToClickedSlide
                >
                    {renderBtn}
                </Swiper>
            ) : (
                <div className="BroadcastHeader__Inner">
                    {renderBtn}
                </div>
            )}
        </div>
    )
}

export default BroadcastHeader;