import React from "react";
import { ReactComponent as LinkArrow } from "./icons/arrow.svg";
import { Winner } from "../../types";
import Headline from "@/components/Headline";
import Typography from "@/components/Typography";
import "./index.scss";

interface Props {
    winner: Winner;
    onClickCardWinner: () => void;
    setWinner: (winner: Winner) => void;
}

const WinnerCard: React.FC<Props> = ({ onClickCardWinner, winner, setWinner }) => {
    const openModalCurrentWinner = (evt: React.MouseEvent<Element, MouseEvent>) => {
        evt.preventDefault();
        onClickCardWinner();
        setWinner(winner);
    };

    return (
        <a
            className="WinnerCard"
            href={winner.link}
            onClick={(evt) => openModalCurrentWinner(evt)}
            target="_blank"
            rel="noreferrer"
        >
            <div className="WinnerCard__Wrap">
                <div className="WinnerCard__LogoWrapper">
                    <div className="WinnerCard__Logo">
                        <img src={winner.image} alt={winner.name} />
                    </div>
                </div>
                <div className="WinnerCard__Content">
                    <Headline level="h3" size="sm" theme="dark">{winner.name}</Headline>
                    <Typography className="WinnerCard__Text" component="p" color="gray" size="sm" weight="regular">{winner.description}</Typography>
                </div>
                <div className="WinnerCard__Link">
                    <LinkArrow />
                </div>
                {/* disabled */}
                {/* {winner.isTop && (
                    <div className="WinnerCard__Top">
                        <Typography component="span" color="light" size="sm" weight="semiBold">1 место</Typography>
                    </div>
                )} */}
            </div>
        </a>
    );
};

export default WinnerCard;
