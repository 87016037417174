import React from 'react';
import Container from '@/components/Container';
import BgDesktop from "./assets/bg.png";
import BgMobile from "./assets/bg-mobile.png";
import GridDesktop from "./assets/grid.png";
import GridMobile from "./assets/grid-mobile.png";
import MedalIcon from "./assets/il_medal.png";
import Headline from '@/components/Headline';
import Typography from '@/components/Typography';
import ActionBtn from '@/components/ActionBtn';
import scrollToSection from '@/utils/scrollToSection';
import { WINNERS_HASH_ID } from '@/constants';
import './index.scss';

const CompetitionHero: React.FC = () => {
    return (
        <section className="CompetitionHero">
            <picture>
                <source srcSet={BgMobile} media="(max-width: 575px)" />
                <source srcSet={BgDesktop} media="(min-width: 576px)" />
                <img src={BgDesktop} className="CompetitionHero__Bg" alt="decoration light" />
            </picture>
            <picture>
                <source srcSet={GridMobile} media="(max-width: 575px)" />
                <source srcSet={GridDesktop} media="(min-width: 576px)" />
                <img src={GridDesktop} className="CompetitionHero__BgGrid" alt="decoration light" />
            </picture>
            <Container className="CompetitionHero__Inner">
                <img className='CompetitionHero__MedalIcon' src={MedalIcon} alt='Medal Icon'/>
                <div className='CompetitionHero__Header'>
                    <Headline level='h2' size='xlg' theme='light' isGradient isCentered>
                        Объявляем
                        <br />финалистов конкурса
                    </Headline>
                    <Headline level='h1' size='xlg' theme='light' isCentered>
                        EdTech-компаний
                    </Headline>
                </div>
                <Typography className='CompetitionHero__Text' size='md' color='light' weight='semiBold' isCentered>
                    Мы получили 118 заявок, среди них отобрали
                    <br />12 лучших по четырём номинациям
                </Typography>
                <ActionBtn
                    className="CompetitionHero__ActionButton"
                    text="Посмотреть финалистов"
                    theme='green'
                    onClick={() => scrollToSection(WINNERS_HASH_ID, 100, 'smooth')}
                />
            </Container>
        </section>
    )
}

export default CompetitionHero;