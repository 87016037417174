import React, { useContext, useEffect, useRef, useState } from "react";
import { BroadcastContext } from "@/context/BroadcastContext";
import classNames from "classnames";
import MessageCard from "@/components/MessageCard";
import Loader from "@/components/Loader";
import PinnedMessage from "../components/PinnedMessage";
import ToStartBtn from "../components/ToStartBtn";
import { ChatType, StreamMessagesType } from "@/types/chatTypes";
import { useUser } from "@/context/UserContext";
import "./index.scss";

type Props = {
    chatData: StreamMessagesType[],
    chatType: ChatType,
    isForModerators: boolean,
    forStage: boolean,
    isCommonChatModerated?: boolean,
}

const ChatBox: React.FC<Props> = ({
    chatData,
    chatType,
    isForModerators,
    forStage,
    isCommonChatModerated
}) => {
    // chat box height
    const chatHeight = isForModerators || forStage ? 535 : 460;

    const { userInfo } = useUser();

    const [showScrollBtn, SetShowScrollBtn] = useState<boolean>(false);
    const { pinnedMessage, activeFilter, isFetchingMessages } = useContext(BroadcastContext);

    const chatBoxRef = useRef<null | HTMLDivElement>(null);
    const chatContainerRef = useRef<null | HTMLDivElement>(null);
    const pinRef = useRef<null | HTMLDivElement>(null);

    const isPinned = pinnedMessage;

    const renderMessages = () => {
        let messages;

        if (isForModerators) {
            messages = chatData?.map(el => {
                return (<MessageCard
                    key={el.id}
                    message={el}
                    isForModerators={isForModerators}
                    isCommonChatModerated={isCommonChatModerated}
                    forStage={forStage}
                />)
            })
        } else if (forStage) {
            messages = chatData?.filter(message => message.moderation_status === "approved").map(el => {
                return (<MessageCard
                    key={el.id}
                    message={el}
                    isForModerators={isForModerators}
                    forStage={forStage}
                />)
            })
        } else if (userInfo) {
            messages = chatData?.filter(message => message.moderation_status === "approved" || message.user.id === userInfo.id).map(el => {
                return (<MessageCard
                    key={el.id}
                    message={el}
                    isForModerators={isForModerators}
                    forStage={forStage}
                />)
            })
        } else {
            messages = chatData?.filter(message => message.moderation_status === "approved").map(el => {
                return (<MessageCard
                    key={el.id}
                    message={el}
                    isForModerators={isForModerators}
                    forStage={forStage}
                />)
            })
        }

        return messages;
    }


    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        scrollToLast("smooth");
    }

    const scrollToLast = (behavior: "smooth" | "instant") => {
        const chatBox = chatBoxRef.current;
        const chatContainer = chatContainerRef.current;

        if (chatBox && chatContainer) {
            chatContainer.scrollTo({
                behavior: behavior,
                top: chatBox.offsetHeight
            });
        }
    }

    useEffect(() => {
        const chatContainer = chatContainerRef.current;
        const chatBox = chatBoxRef.current;

        const handleScroll = () => {
            if (chatContainer && chatBox) {
                const containerHeight = chatContainer.offsetHeight;
                const chatBoxHeight = chatBox.offsetHeight;
                const scrollPostion = chatContainer.scrollHeight - chatContainer.scrollTop;

                const showScrollBtn = scrollPostion > containerHeight + 200 && chatBoxHeight > containerHeight;

                SetShowScrollBtn(showScrollBtn);
            }

        };

        if (activeFilter === "popular" && chatContainer) {
            chatContainer.scrollTo({
                behavior: "smooth",
                top: 0,
            });

        } else {
            scrollToLast("instant");
            SetShowScrollBtn(false);
        }

        chatContainer?.addEventListener("scroll", handleScroll);

        return () => {
            chatContainer?.removeEventListener("scroll", handleScroll);
        };

    }, [chatData.length, chatType, activeFilter]);

    useEffect(() => {
        const pin = pinRef.current;
        const chatContainer = chatContainerRef.current;

        if (isPinned && pin && chatContainer) {
            const pinHeight = pin.clientHeight;
            chatContainer.style.height = chatHeight - pinHeight + 65 + 'px';

        } else if (chatContainer) {
            chatContainer.style.height = chatHeight + 'px';
        }

    }, [isPinned, pinRef, chatType]);

    const handleToPinned = () => {
        const container = chatContainerRef.current;
        const elementId = pinnedMessage?.id.toString();
        const marginTop = 80;

        if (elementId && container) {
            const element = document.getElementById(elementId);

            if (element) {
                const elementPosition = element.offsetTop - container.offsetTop;

                container.scrollTop = elementPosition - marginTop; // Scroll to the element
            }
        }
    }

    return (
            <div className={classNames({
                "ChatBox__Wrapper": true,
                "ChatBox__Wrapper--for-moderators": isForModerators || forStage,
            })}>

                {isPinned && chatType === "for_speaker" &&
                    <div className="ChatBox__Pin" ref={pinRef} onClick={handleToPinned}>
                        <PinnedMessage message={pinnedMessage.message} />
                    </div>
                }

                <div className={classNames({
                    "ChatBox__Container": true,
                    "ChatBox__Container--pinned": isPinned,
                })}
                    ref={chatContainerRef}
                >
                    {
                        isFetchingMessages ?
                            <Loader />
                            :
                            <div className={classNames({
                                "ChatBox": true,
                                "ChatBox--empty": chatData.length === 0,
                            })}
                                ref={chatBoxRef}
                            >
                                {
                                    chatData.length > 0 ?
                                        renderMessages()
                                        :
                                        <p className="ChatBox__Empty">Здесь пока нет сообщений.</p>
                                }
                            </div>
                    }
                </div>
                {showScrollBtn &&
                    <ToStartBtn
                        className="ChatBox__ScrollBtn"
                        onClick={handleClick}
                    />
                }
            </div>
    )
}

export default ChatBox;