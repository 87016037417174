import React from "react";
import Headline from "@/components/Headline";
import Typography from "@/components/Typography";
import Container from "@/components/Container";
import Decorations from "./assets/decoration.png";
import DecorationsMobile from "./assets/decoration_mobile.png";
import useMedia from "@/hooks/useMedia";
import { PARTNERS_HASH_ID } from "@/constants";
import { SUPPORT_EMAIL } from "@/constants/common";
import { ReactComponent as Logo01 } from "./assets/1.svg";
import { ReactComponent as Logo02 } from "./assets/2.svg";
import { ReactComponent as Logo03 } from "./assets/3.svg";
import { ReactComponent as Logo04 } from "./assets/4.svg";
import { ReactComponent as Logo05 } from "./assets/5.svg";
import { ReactComponent as Logo06 } from "./assets/6.svg";
import { ReactComponent as Logo07 } from "./assets/7.svg";
import "./index.scss";

const MEDIA_LIST = [
    {
        logo: <Logo01 />,
        name: "",
        link: "",
    },
    {
        logo: <Logo02 />,
        name: "",
        link: "",
    },
    {
        logo: <Logo03 />,
        name: "",
        link: "",
    },
    {
        logo: <Logo04 />,
        name: "",
        link: "",
    },
    {
        logo: <Logo05 />,
        name: "",
        link: "",
    },
    {
        logo: <Logo06 />,
        name: "",
        link: "",
    },
    {
        logo: <Logo07 />,
        name: "",
        link: "",
    },
];

const MediaPartners: React.FC = () => {
    const { isMobile } = useMedia();

    const renderList = MEDIA_LIST.map((el, index) => (
        <div key={index} className="MediaPartners__Item">
            <div className="MediaPartners__ItemImg">{el.logo}</div>
        </div>
    ));

    return (
        <section className="MediaPartners" id={PARTNERS_HASH_ID}>
            <Container className="MediaPartners__Container">
                <Headline level="h2" size="xlg" theme="light" isCentered>
                    Партнеры
                </Headline>
                <div className="MediaPartners__Body">
                    <div className="MediaPartners__List">{renderList}</div>
                    <div className="MediaPartners__Action">
                        <picture className="MediaPartners__Decorations">
                            <source
                                srcSet={DecorationsMobile}
                                media="(max-width: 575px)"
                            />
                            <source
                                srcSet={Decorations}
                                media="(min-width: 576px)"
                            />
                            <img
                                src={Decorations}
                                alt="Decorations"
                                loading="lazy"
                            />
                        </picture>
                        <Typography
                            className="MediaPartners__ActionText"
                            size="xlg"
                            weight="semiBold"
                            color="light"
                        >
                            {isMobile
                                ? "Отправьте заявку,\nчтобы стать нашим\nпартнером"
                                : "Отправьте заявку, чтобы стать\nнашим партнером"}
                        </Typography>
                        <div className="MediaPartners__Btn">
                            {SUPPORT_EMAIL}
                        </div>
                    </div>
                </div>
            </Container>
        </section>
    );
};

export default MediaPartners;
