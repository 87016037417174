import React from "react";
import { StreamDataType } from "@/types/chatTypes";
// import StreamNote from "./StreamNote";
import "./index.scss";

type Props = {
    currentStream: StreamDataType,
}

const VideoPlayer: React.FC<Props> = ({ currentStream }) => {
    return (
        <div className="VideoPlayer">
            <div className="VideoPlayer__Player">
                {currentStream.is_use_fallback ? (
                    <div
                        className="VideoPlayer__Iframe"
                        dangerouslySetInnerHTML={{ __html: currentStream.fallback_iframe_url }} />
                ) : (
                    <div
                        className="VideoPlayer__Iframe"
                        dangerouslySetInnerHTML={{ __html: currentStream.iframe_url }} />
                )}
            </div>
            {/* removed from UI */}
            {/* {!!currentStream.next_session_description &&
                <StreamNote
                    className="VideoPlayer__Note"
                    currentStream={currentStream.title}
                    nextStream={currentStream.next_session_description}
                />
            } */}
        </div>
    )
}

export default VideoPlayer;