import React from "react";
import classNames from "classnames";
import { ReactComponent as ArrowDown } from "./assets/arrow-down.svg";
import "./index.scss";

type Props = {
    onClick: any,
    className?: string
}

const ExpandBtn: React.FC<Props> = ({ onClick, className }) => {
    return (
        <button
            className={classNames(className, "ExpandBtn")}
            type="button"
            onClick={onClick}
        >
            <ArrowDown className="ExpandBtn__Icon" />
        </button>
    )
}

export default ExpandBtn;