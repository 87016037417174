import React, { useContext, useEffect, useState } from "react";
import { BroadcastContext } from "@/context/BroadcastContext";
import Container from "@/components/Container";
import BroadcastHeader from "@/components/BroadcastHeader";
import VideoPlayer from "./VideoPlayer";
import LiveChat from "@/components/LiveChat";
import WaitingScreen from "./common/WaitingScreen";
import Headline from "@/components/Headline";
import BackwordBtn from "./common/BackwordBtn";
import Popup from "@/components/Popup";
import { useToggle } from "react-use";
import UserBlocked from "./common/UserBlocked";
import useMedia from "@/hooks/useMedia";
import classNames from "classnames";
import StreamProgram from "./StreamProgram";
import { useLocation } from "react-router-dom";
import routes from "@/routes";
import { ErrorBoundary } from "react-error-boundary";
import LiveChatFallback from "@/components/LiveChatFallback";
import { STREAM_HASH_ID } from "@/constants";
import "./index.scss";

type Props = {
    withBackwordBtn?: boolean;
    className?: string;
}

const Broadcast: React.FC<Props> = ({ withBackwordBtn = true, className }) => {
    const [isBlockedPopup, togglePopup] = useToggle(false);
    const [showStreamProgram, setShowStreamProgram] = useState<boolean>(false);

    const { pathname } = useLocation();

    const { activeBroadcast, isUserBlocked, commonBroadcastMessages, forSpeakerBroadcastMessages, chatType, streamNum } = useContext(BroadcastContext);

    const { isMobile } = useMedia();

    useEffect(() => {
        togglePopup(isUserBlocked);
    }, [isUserBlocked])

    useEffect(() => {

        if (pathname.includes(`${routes.broadcast}`)) {
            setShowStreamProgram(true);
        } else {
            setShowStreamProgram(false)
        }

    }, [pathname])

    return (
        <Container className={classNames("Broadcast", className)}>
            {withBackwordBtn && <BackwordBtn className="Broadcast__BackwordBtn" />}
            <Headline className="Broadcast__Title" level="h2" size="xlg" theme="light">Онлайн-трансляция</Headline>

            {isMobile &&
                <div className="Broadcast__Header">
                    <BroadcastHeader isForModerators={false} />
                </div>
            }

            <div className="Broadcast__Wrapper" id={STREAM_HASH_ID}>
                <div className="Broadcast__Col">
                    {!isMobile && (<BroadcastHeader isForModerators={false} />)}

                    {
                        activeBroadcast && activeBroadcast.is_active ?
                            <VideoPlayer currentStream={activeBroadcast} />
                            :
                            <WaitingScreen time={streamNum === 1 ? "9:15" : "12:00"} />
                    }
                </div>
                <ErrorBoundary FallbackComponent={LiveChatFallback}>
                    <LiveChat
                        isCollapsible
                        chatData={chatType === "common" ? commonBroadcastMessages : forSpeakerBroadcastMessages}
                        chatType={chatType}
                    />
                </ErrorBoundary>
            </div>

            {showStreamProgram && <StreamProgram streamNum={streamNum} />}

            <Popup
                className="Broadcast__BlockedPopup"
                isPopupActive={isBlockedPopup}
                closePopup={togglePopup}
                position={isMobile ? "bottom" : "center"}
                withCloseBtn
            >
                <UserBlocked />
            </Popup>
        </Container>
    );
};

export default Broadcast;
