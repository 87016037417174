import React, { useContext } from "react";
import classNames from "classnames";
import { ChatTabType, ChatType } from "@/types/chatTypes";
import ExpandBtn from "../components/ExpandBtn";
import ChatFilter from "../components/ChatFilter";
import { BroadcastContext } from "@/context/BroadcastContext";
import Popup from "@/components/Popup";
import useMedia from "@/hooks/useMedia";
import { useToggle } from "react-use";
import WarningMessage from "@/components/WarningMessage";
import "./index.scss";

type Props = {
    chatToggle: (chatType: ChatType) => void,
    chatType: ChatType,
    realChatType: ChatType,
    isExpanded?: boolean,
    isForModerators: boolean,
    isCollapsible: boolean,
    toggleExpand?: () => void,
    tabs: ChatTabType[],
    streamNum: number,
    questionSum: number,
    isCommonChatModerated?: boolean,
    forStage: boolean,
}

const ChatHeader: React.FC<Props> = ({
    chatType,
    realChatType,
    chatToggle,
    isExpanded,
    isForModerators,
    isCollapsible,
    toggleExpand,
    tabs,
    streamNum,
    questionSum,
    isCommonChatModerated,
    forStage,
}) => {
    const { clearAllMessages } = useContext(BroadcastContext);
    const { isMobile } = useMedia();
    const [isDeleteActive, toggleDelete] = useToggle(false);

    const renderMenu = tabs?.map((el, index) => {
        return (
            <li
                key={index}
                className={classNames({
                    "ChatHeader__Section": true,
                    "ChatHeader__Section--active": el.chatType === chatType,
                })}
                onClick={() => chatToggle(el.chatType)}
            >
                {el.tab}
            </li>
        )
    });

    return (
        <>
            <ul className={classNames({
                "ChatHeader": true,
                "ChatHeader--expanded": isExpanded,
                "ChatHeader--for-moderators": isForModerators || forStage,
            })}>
                {!isExpanded && isCollapsible && <h3 className="ChatHeader__Collapsed-Tite">Открыть чат</h3>}
                {isCollapsible && <ExpandBtn
                    className="ChatHeader__Collapsed-Icon"
                    onClick={toggleExpand}
                />}

                {isExpanded && (
                    <>
                        {renderMenu}
                        {chatType === "for_speaker" && !isCommonChatModerated && (
                            <ChatFilter
                                className={isForModerators || forStage ? "ChatHeader__Filter-Moderators" : "ChatHeader__Filter"}
                                openToRight={isForModerators || forStage}
                            />
                        )}
                        {isForModerators && (
                            <>
                                <span className="ChatHeader__Stream-Num">Стрим&nbsp;{streamNum}</span>
                                <div className="ChatHeader__Moderators-Controls">
                                    <button className="ChatHeader__Clear-Btn" type="button" onClick={toggleDelete}>Очистить</button>
                                    <span className="ChatHeader__Question-Sum">{questionSum > 4 || questionSum === 0 ? `${questionSum} вопросов` : questionSum === 1 ? `${questionSum} вопрос` : `${questionSum} вопроса`}</span>
                                </div>
                            </>
                        )}
                    </>
                )}
            </ul>
            <Popup
                className="ChatHeader__Popup"
                isPopupActive={isDeleteActive}
                closePopup={toggleDelete}
                position={isMobile ? "bottom" : "center"}
                withCloseBtn
            >
                <WarningMessage
                    togglePopup={() => toggleDelete(false)}
                    executeFunc={() => clearAllMessages(streamNum, realChatType)}
                    text="Это действие нельзя будет отменить."
                    question="Очистить все сообщение?"
                />
            </Popup>
        </>
    )
}

export default ChatHeader;
