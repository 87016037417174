import React, { useContext, useEffect } from "react";
import { BroadcastContext } from "@/context/BroadcastContext";
import BroadcastHeader from "@/components/BroadcastHeader";
import LiveChat from "@/components/LiveChat";
import Container from "@/components/Container";
import { ErrorBoundary } from "react-error-boundary";
import LiveChatFallback from "@/components/LiveChatFallback";
import Headline from "@/components/Headline";
import "./index.scss";

const QuestionsModeration: React.FC = () => {
    const { toggleChatType, questionMessagesForModerators } = useContext(BroadcastContext);

    useEffect(() => {
        toggleChatType("for_speaker");
    }, [])

    return (
        <main className="QuestionsModeration">
            <Container className="QuestionsModeration__Container">
                <Headline level="h2" size="lg" theme="light" isCentered>Модерация</Headline>
                <div className="QuestionsModeration__Header">
                    <BroadcastHeader isForModerators />
                </div>
                <ErrorBoundary FallbackComponent={LiveChatFallback}>
                    <LiveChat
                        isCollapsible={false}
                        isForModerators={true}
                        chatData={questionMessagesForModerators}
                        chatType={"for_speaker"}
                    />
                </ErrorBoundary>
            </Container>
        </main>
    )
}

export default QuestionsModeration;