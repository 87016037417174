import React, { useContext } from "react";
import { ReactComponent as LikedIcon } from "../assets/liked-icon.svg";
import { ReactComponent as PinIcon } from "../assets/pin-icon.svg";
import { BroadcastContext } from "@/context/BroadcastContext";
import { ModerationStatusType } from "@/types/chatTypes";
import classNames from "classnames";
import { useToggle } from "react-use";
import "./index.scss";

type Props = {
    likes: number,
    messageId: number,
    userId: number,
    moderationStatus: ModerationStatusType,
    isPinned: boolean,
    isCommonChatModerated?: boolean,
}

const ModerationControls: React.FC<Props> = ({
    likes,
    messageId,
    userId,
    moderationStatus,
    isCommonChatModerated,
    isPinned
}) => {
    const { approveMessage, rejectMessage, pinMessage, unpinMessage, banUser } = useContext(BroadcastContext);

    const [isBlocked, toggleIsBlocked] = useToggle(false);

    const handleApprove = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        approveMessage(messageId)
    }

    const handleReject = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        rejectMessage(messageId)
    }

    const handlePin = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        if (isPinned) {
            unpinMessage(messageId)
        } else {
            pinMessage(messageId)
        }
    }

    const handleBanUser = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        const ban = await banUser(userId)
        toggleIsBlocked(ban);
    }

    if (isCommonChatModerated && moderationStatus === "approved") {
        return (
            <button
                className={classNames({
                    "ModerationControls__Btn ModerationControls__Btn--ban": true,
                    "ModerationControls__Btn--banned": isBlocked,
                })}
                type="button"
                onClick={handleBanUser}
                disabled={isBlocked}
            >
                {isBlocked ? "Заблокирован" : "Заблокировать"}
            </button>
        )
    }

    return (
        <div className="ModerationControls__Wrapper">
            {
                moderationStatus === "approved" && !isCommonChatModerated ? (
                    <>
                        <button
                            className={classNames({
                                "ModerationControls__Btn ModerationControls__Btn--pin": true,
                                "ModerationControls__Btn--pinned": isPinned,
                            })}
                            type="button"
                            onClick={handlePin}
                        >
                            {isPinned ? "Закреплено" : "Закрепить"}
                            <PinIcon />
                        </button>

                        <div className="ModerationControls__Likes">
                            {likes}
                            <LikedIcon />
                        </div>
                    </>
                ) : (
                    <div className="ModerationControls">
                        <button
                            className="ModerationControls__Btn"
                            type="button"
                            onClick={handleApprove}
                        >
                            Принять
                        </button>
                        <button
                            className={classNames({
                                "ModerationControls__Btn": true,
                                "ModerationControls__Btn--red": moderationStatus !== "rejected",
                                "ModerationControls__Btn--rejected": moderationStatus === "rejected",
                            })}
                            type="button"
                            onClick={handleReject}
                        >
                            {moderationStatus === "rejected" ? "Отклонено" : "Отклонить"}
                        </button>
                    </div>
                )
            }


        </div>
    )
}

export default ModerationControls;