import React from "react";
import WaittingBg from "./assets/bg-video-start.jpg";
import Typography from "@/components/Typography";
import "./index.scss";

type Props = {
    time: string
}

const WaitingScreen: React.FC<Props> = ({
    time,
}) => {
    return (
        <div className="WaitingScreen">
            <img className="WaitingScreen__Bg" src={WaittingBg} alt="waitting bg" />
            <div className="WaitingScreen__Body">
                <Typography component="div" size="md" weight="semiBold" color="light" opacity={0.4}>
                    Начало трансляции
                </Typography>
                <div className="WaitingScreen__Time">
                    <Typography component="div" size="xlg" weight="semiBold" color="light" gradient="green">
                        {time} по МСК
                    </Typography>
                    <Typography component="span" size="xlg" weight="semiBold" color="light" opacity={0.4}>
                        (UTC +3)
                    </Typography>
                </div>
            </div>
        </div>
    )
}

export default WaitingScreen;