
// Conference
export const ABOUT_CONFERENCE_HASH_ID = "aboutConference";
export const PROGRAM_HASH_ID= "program";
export const STREAM_HASH_ID= "stream";
export const TARIFFS_HASH_ID = "choose_tariff";
export const SPEAKERS_HASH_ID = "speakers";
export const FAQ_HASH_ID = "faq";
export const PARTNERS_HASH_ID = "partners";
export const FORM_HASH_ID = "conferenceForm";

// contest
export const ABOUT_CONTEST_HASH_ID = "aboutContest";
export const PARTICIPATES_HASH_ID = "participates";
export const AWARDS_HASH_ID = "awards";
export const NOIMATIONS_HASH_ID = "nominations";
export const COMPETITION_FAQ_HASH_ID = "competitionFAQ";
export const JURY_HASH_ID = "jury";
export const STAGES_HASH_ID = "stages";
export const WINNERS_HASH_ID = "winner";