import React from "react";
import classNames from "classnames";
import { ReactComponent as ArrowDown } from "./assets/arrow-down.svg";
import { ReactComponent as PinIcon } from "./assets/pin-icon.svg";
import { useToggle } from "react-use";
import "./index.scss";

type Props = {
    className?: string,
    message: string
}

const PinnedMessage: React.FC<Props> = ({
    className,
    message
}) => {

    const [show, toggle] = useToggle(false);

    const handleClick = (e: React.MouseEvent<SVGSVGElement>)=>{
        e.stopPropagation();
        toggle();
    }

    return (
        <div className={classNames(className, {
            "PinnedMessage": true,
            "PinnedMessage--show": show,
        })}
        >
            <div className="PinnedMessage__Header">
                <span className="PinnedMessage__Header-text">
                    <PinIcon />
                    Закрепленное сообщение
                </span>
                <ArrowDown
                    className="PinnedMessage__Icon"
                    onClick={handleClick}
                />
            </div>
            <p className="PinnedMessage__Text">
                {message}
            </p>
        </div>
    )
};

export default PinnedMessage;